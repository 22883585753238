"use client";

/**
 * Third-party libraries.
 */
import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

/**
 * Application context.
 */

export type NetworkContext = {
  /**
   * Indicates that the application is connected to the network.
   */
  connected: boolean;
  /**
   * Sets the flag which indicates that the network is still loading.
   */
  loading: boolean;
  graphqlConnected: boolean;
  setGraphqlConnected: Dispatch<SetStateAction<boolean>>;
  graphqlLoading: boolean;
  setGraphqlLoading: Dispatch<SetStateAction<boolean>>;
  twilioConnected: boolean;
  setTwilioConnected: Dispatch<SetStateAction<boolean>>;
  twilioLoading: boolean;
  setTwilioLoading: Dispatch<SetStateAction<boolean>>;
};

/**
 * Application context.
 */
const NetworkContext = React.createContext<NetworkContext>({
  connected: false,
  loading: true,
  graphqlConnected: false,
  setGraphqlConnected: () => {},
  graphqlLoading: false,
  setGraphqlLoading: () => {},
  twilioConnected: false,
  setTwilioConnected: () => {},
  twilioLoading: false,
  setTwilioLoading: () => {}
});

/**
 * Use Application Context hook.
 */
export const useNetworkContext = () => {
  return React.useContext(NetworkContext);
};

/**
 * Application context provider.
 */
export const NetworkContextProvider = ({
  children
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const [graphqlConnected, setGraphqlConnected] = useState<boolean>(false);
  const [graphqlLoading, setGraphqlLoading] = useState<boolean>(true);
  const [twilioConnected, setTwilioConnected] = useState<boolean>(false);
  const [twilioLoading, setTwilioLoading] = useState<boolean>(true);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <NetworkContext.Provider value={{
    connected: graphqlConnected && twilioConnected,
    loading: graphqlLoading || twilioLoading,
    graphqlConnected,
    setGraphqlConnected,
    graphqlLoading,
    setGraphqlLoading,
    twilioConnected,
    setTwilioConnected,
    twilioLoading,
    setTwilioLoading
  }} data-sentry-element="unknown" data-sentry-component="NetworkContextProvider" data-sentry-source-file="network-context.tsx">
      {children}
    </NetworkContext.Provider>;
};