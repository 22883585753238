"use client";

/**
 * Third-party libraries.
 */
// import { Call } from "@twilio/voice-sdk";
import { HTMLAttributes, PropsWithChildren, useMemo } from "react";

/**
 * Project components.
 */
import { CommunicationLogGroup, CommunicationLogGroupProps } from "@/components/client/communication-log";
import InfiniteScroll, { InfiniteScrollProps } from "@/components/client/scroll/infinite-scroll";
import { CommunicationLog as CommunicationLogType } from "./types";

// =============================================================================
// Communication Log Row
// =============================================================================

/**
 * A row in the communication log list.
 * This provides a container with a bottom border to separate the rows.
 */
export const CommunicationLogRow = ({
  children,
  className
}: PropsWithChildren<{
  /**
   * CSS classes for the communication log row.
   */
  className?: string;
}>) => {
  return <div className={`flex w-full flex-col items-start justify-start gap-4 self-stretch border-b bg-white ${className}`} style={{
    borderBottom: "1px solid rgba(0,0,0,0.1)"
  }} data-sentry-component="CommunicationLogRow" data-sentry-source-file="communication-log.tsx">
      {children}
    </div>;
};

// =============================================================================
// Communication Log
// =============================================================================

export type CommunicationLogProps = {
  /**
   * Callback function when the user scrolls to the bottom of the list.
   */
  onMaxScroll?: () => Promise<void>;
  /**
   * CSS classes for the communication log container.a
   */
  classNames?: HTMLAttributes<HTMLDivElement>["className"];
  /**
   * Past communication logs.
   *
   * Pass an empty array if there are no logs.
   */
  data: CommunicationLogGroupProps["data"][];
  /**
   * Indicates that there are more communication logs to fetch.
   */
  hasMoreData?: InfiniteScrollProps["hasMore"];
  /**
   * Hides the status indicator at the bottom of the list.
   * This hides the "No more items" message and the loading indicator.
   */
  hideListStatusIndicator?: InfiniteScrollProps["hideStatusIndicator"];
  /**
   * Indicates that the initial communication logs are being fetched.
   * Shows a loading indicator on the whole team inbox log panel.
   */
  loading?: InfiniteScrollProps["loading"];
  /**
   * Indicates that the communication logs are fetching more data.
   */
  loadingMoreData?: InfiniteScrollProps["loadingMore"];
  /**
   * Callback function when the user clicks on a communication log.
   */
  onLogClick?: CommunicationLogGroupProps["onLogClick"];
} & HTMLAttributes<HTMLDivElement>;
const MIN_WIDTH = 340;

/**
 * A list of communication logs grouped by date.
 */
export const CommunicationLog = ({
  classNames = "",
  data,
  hasMoreData,
  hideListStatusIndicator,
  loading,
  loadingMoreData,
  onLogClick,
  onMaxScroll,
  ...rest
}: CommunicationLogProps) => {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  /**
   * All of the logs from different groups.
   */
  const logs: CommunicationLogType[] = useMemo(() => data.reduce((acc, group) => {
    acc.push(...group.logs);
    return acc;
  }, [] as CommunicationLogType[]), [data]);
  return <div className={`inline-flex h-full w-full flex-col items-start justify-start bg-neutral-grey-surface min-w-[${MIN_WIDTH}] ${classNames}`} {...rest} data-sentry-component="CommunicationLog" data-sentry-source-file="communication-log.tsx">
      <InfiniteScroll className={`w-full overflow-y-auto min-w-[${MIN_WIDTH}] overflow-x-hidden`} hasMore={hasMoreData} hideStatusIndicator={!loading && !loadingMoreData && !logs.length || hideListStatusIndicator} loading={loading} loadingMore={loadingMoreData} onLoadMore={onMaxScroll} data-sentry-element="InfiniteScroll" data-sentry-source-file="communication-log.tsx">
        {!loading && data.map(group => <CommunicationLogGroup key={group.label} data={group} onLogClick={onLogClick} />)}
      </InfiniteScroll>
    </div>;
};