/**
 * Third-party libraries.
 */
import dayjs from "dayjs";
import timezoneDayJS from "dayjs/plugin/timezone";
import utcDayJS from "dayjs/plugin/utc";

/**
 * Project components.
 */

dayjs.extend(utcDayJS); // Allows us to use dayjs.tz()
dayjs.extend(timezoneDayJS); // Allows us to use dayjs.tz()

// ===========================================================================
// Contants
// ===========================================================================

/** One minute in milliseconds */
export const MIN_IN_MS = 60 * 1000;

/** One hour in milliseconds */
export const HOUR_IN_MS = 60 * MIN_IN_MS;

/** One day in milliseconds */
export const DAY_IN_MS = 24 * HOUR_IN_MS;

// ===========================================================================
// Formatters
// ===========================================================================

/**
 * A utility for formatting durations.
 *
 * @example
 *  formatDuration({ duration: 3600000 }) // -> "1:00:00"
 *  formatDuration({ duration: 3600000, format: "readable-short" }) // -> "1 hr"
 *  formatDuration({ from: "2024-08-20T09:00:00.000Z", to: "2024-08-20T13:30:00.000Z" }) // -> "4 hrs and 30 mins"
 */
export function formatDuration(
  params: {
    /**
     * - numeric: e.g. "01:00:00" (does not adapt, always {hours}:{minutes}:{seconds})
     * - readable-short: e.g. "1 hr and 30 mins" (Adapts when no minutes/hours)
     * - readable-long: e.g. "1 hour and 30 minutes" (Adapts when no minutes/hours)
     *
     * @defaultValue: "numeric"
     */
    format?: "numeric" | "readable-short" | "readable-long";
    /**
     * Pick which units to display. False to hide.
     * @defaultValue { hours: true, minutes: true, seconds: true }
     */
    display?: { hours?: boolean; minutes?: boolean; seconds?: boolean };
  } & ({ duration: number } | { from: Date | string; to: Date | string }),
): string {
  if ("duration" in params) {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - params.duration);

    return _formatSMHD({
      startDate: startDate,
      endDate: endDate,
      format: params.format ?? "numeric",
      display: params.display,
    });
  }

  if ("from" in params && "to" in params) {
    return _formatSMHD({
      startDate: new Date(params.from),
      endDate: new Date(params.to),
      format: params.format ?? "numeric",
      display: params.display,
    });
  }

  return "";
}

/**
 * Local helper function to format S - seconds, M - minutes, H - hours, D - days
 */
function _formatSMHD(params: {
  startDate: Date | string;
  endDate: Date | string;
  format: "numeric" | "readable-short" | "readable-long";
  display?: {
    seconds?: boolean;
    minutes?: boolean;
    hours?: boolean;
    days?: boolean;
    months?: boolean;
  };
}) {
  const milliseconds =
    new Date(params.endDate).getTime() - new Date(params.startDate).getTime();

  const seconds = Math.floor(milliseconds / 1000);

  // ===========================================================================
  // Utilities
  // ===========================================================================

  const baseShow = (
    value: number,
    _options?: {
      format?: (_number: number) => string;
      /** When true, this unit will not be displayed. Show when zero @defaultValue false. */
      showZero?: boolean;
      display?: boolean;
    },
  ) => {
    const options = {
      format: (_number: number) => _number.toString(),
      showZero: false,
      display: true,
      ..._options,
    };

    if (options.display && value > 0) return options.format(value);

    if (options.showZero) return options.format(value);

    return null;
  };

  const showSeconds: typeof baseShow = (_seconds, options) => {
    return baseShow(_seconds, {
      display: params.display?.seconds ?? true,
      ...options,
    });
  };

  const showMinutes: typeof baseShow = (_minutes, options) => {
    return baseShow(_minutes, {
      display: params.display?.minutes ?? true,
      ...options,
    });
  };

  const showHours: typeof baseShow = (_hours, options) => {
    return baseShow(_hours, {
      display: params.display?.hours ?? true,
      ...options,
    });
  };

  const showDays: typeof baseShow = (_days, options) => {
    return baseShow(_days, {
      display: params.display?.days ?? true,
      ...options,
    });
  };

  /** Joins arrays of strings but removes falsy values. */
  const joinButRemoveEmpty = (
    array: (string | undefined | null | false)[],
    separator: string,
  ): string => {
    return array.filter(Boolean).join(separator);
  };

  // ===========================================================================
  // Per Format Logic
  // ===========================================================================

  // NUMERIC
  if (params.format === "numeric") {
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);
    const remainingMinutes = Math.floor(
      (milliseconds / 1000 / 60 / 60 - hours) * 60,
    );
    const remainingSeconds = Math.floor(
      ((milliseconds / 1000 / 60 / 60 - hours) * 60 - remainingMinutes) * 60,
    );

    return joinButRemoveEmpty(
      [
        showHours(hours, { format: padZero, showZero: true }),
        showMinutes(remainingMinutes, { format: padZero, showZero: true }),
        showSeconds(remainingSeconds, { format: padZero, showZero: true }),
      ],
      ":",
    );
  }
  // READABLE-SHORT
  else if (params.format === "readable-short") {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    return joinButRemoveEmpty(
      [
        showDays(days, { format: (_days) => _days + "d" }),
        showHours(remainingHours, { format: (_hours) => _hours + "h" }),
        showMinutes(remainingMinutes, { format: (_minutes) => _minutes + "m" }),
        showSeconds(remainingSeconds, { format: (_seconds) => _seconds + "s" }),
      ],
      " ",
    );
    // Note, we can still extend this function to support months and years.
    // Only that once you support months, you need to think about `daysInMonth`, `leapYears`, etc.
  }
  // READABLE-LONG not implemented.
  return "";
}

function padZero(num: number): string {
  return num.toString().padStart(2, "0");
}

/**
 * Shows the time in 12-hour format from a 'Date'-like object or string.
 *
 * @example
 *   "2024-08-20T09:00:00.000Z" -> "09:00:00 AM"
 *   "2024-08-20T16:00:00.000Z" -> "04:00:00 PM"
 */
export function formatTime(
  date: Date | string,
  options?: {
    /** True, prints the AM or PM in lowercase. @defaultValue true */
    lowercase?: boolean;
  },
): string {
  if (options?.lowercase) {
    return dayjs(date).format("hh:mm:ss a");
  }

  return dayjs(date).format("hh:mm:ss A");
}

/**
 * Shows the time in 12-hour format from a 'Date'-like object or string.
 *
 * @example
 *   "2024-08-20T09:00:00.000Z" -> "August 20 2024"
 *   "2024-08-20T16:00:00.000Z" -> "August 20 2024"
 */
export function formatDate(
  date: Date | string,
  options?: {
    /** Any compatible format to add at the end of `dayjs().format()`. @defaultValue "MMMM Do YYYY" */
    format?: string;
  },
): string {
  return dayjs(date).format(options?.format ?? "MMMM Do YYYY");
}
