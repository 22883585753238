import { CommunicationLogStatus } from "@/components/client/communication-log";
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import Icon from "@ant-design/icons";
import { useMemo } from "react";
import { PhoneIcon } from "./";

/**
 * Properties of the IconCommunicationLogPhone component.
 */
type IconCommunicationLogPhone = {
  /**
   * Indicates that the icon is inside an active component
   */
  active?: boolean;
  /**
   * Size of the icon.
   */
  size?: "medium" | "small" | "large";
  /**
   * Status of the communication log.
   */
  status: CommunicationLogStatus;
};

/**
 * Displays a call icon.
 *
 * This icon changes the color depending on the communication log status
 * and the active status of the call.
 */
export function IconCommunicationLogPhone(props: IconCommunicationLogPhone) {
  // ===========================================================================
  // States
  // ===========================================================================
  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    return CommunicationLogUtility.getCardColor({
      active: props.active,
      status: props.status
    });
  }, [props.active, props.status]);

  /**
   * Size of the icon.
   */
  const size = useMemo(() => {
    switch (props.size) {
      case "small":
        return "!text-lg";
      case "large":
        return "!text-2xl";
      case "medium":
        return "!text-xl";
      default:
        throw new Error("Invalid size");
    }
  }, [props.size]);

  // ===========================================================================
  // Render
  // ===========================================================================

  return <Icon component={PhoneIcon} className={`${size} ${color.icon} ${color.iconHover}`} rotate={
  // Rotate the icon if it is explicitly ended by either party.
  [CommunicationLogStatus.CANCELED, CommunicationLogStatus.COMPLETED, CommunicationLogStatus.DECLINED].includes(props.status) ? 135 : 0} data-sentry-element="Icon" data-sentry-component="IconCommunicationLogPhone" data-sentry-source-file="icon-communication-log-phone.tsx" />;
}