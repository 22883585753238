/**
 * Third-party libraries.
 */
import { UserOutlined } from "@ant-design/icons";
import { Avatar, AvatarProps, Badge, BadgeProps } from "antd";
import { useMemo } from "react";

/**
 * Project components.
 */
import { UserAvailabilityStatus } from "@/components/client/graphql";

/**
 * Properties of the user avatar component.
 */
export type UserAvatarProps = {
  /**
   * Initials of the user to display. A user icon will be displayed if this is not provided or is null.
   * This can't be more than two (2) characters.
   */
  initials?: string;
  /**
   * If true, the badge will not be displayed.
   */
  noBadge?: boolean;
  /**
   * Callback when the user avatar is clicked.
   */
  onClick?: AvatarProps["onClick"];
  /**
   * Size of the user avatar.
   *
   * @default
   * default
   */
  size?: AvatarProps["size"];
  /**
   * Status of the user. Displays the corresponding badge color.
   */
  status?: UserAvailabilityStatus;
  /**
   * Additional properties for the avatar component.
   */
  style?: AvatarProps["style"];
};

/**
 * Displays a user avatar.
 */
export default function UserAvatar({
  initials,
  noBadge,
  onClick,
  status = UserAvailabilityStatus.Offline,
  size = "default",
  style
}: UserAvatarProps) {
  if ((initials?.length ?? 0) > 2) {
    throw Error("Initials can't be more than two (2) characters.");
  }

  // ===========================================================================
  // States
  // ===========================================================================

  /**
   * Badge status to display the corresponding color.
   */
  const badgeStatus = useMemo<BadgeProps["status"]>(() => {
    switch (status) {
      case UserAvailabilityStatus.Available:
        return "success";
      case UserAvailabilityStatus.Break:
        return "warning";
      case UserAvailabilityStatus.Busy:
      case UserAvailabilityStatus.WrappingUp:
        return "default";
      case UserAvailabilityStatus.Ringing:
      case UserAvailabilityStatus.OnACall:
        // The color of this status will be overriden below by the "badgeColorOverride".
        return "processing";
      case UserAvailabilityStatus.Offline:
        return "default";
      default:
        throw new Error("User status not supported.");
    }
  }, [status]);

  /**
   * This will override the "processing" status of the badge to make it red.
   */
  const badgeColorOverride = useMemo(() => {
    if (status === UserAvailabilityStatus.Busy || status === UserAvailabilityStatus.WrappingUp) {
      return "var(--semantic-red)";
    }
    return undefined;
  }, [status]);
  const avatarStyle = {
    background: "#f0f1f9",
    color: "#4f537b",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "125%"
  };
  const getBadgeOffset: () => [string | number, string | number] = () => {
    switch (size) {
      case "default":
        return [-3, 28];
      case "small":
        return [-2, 20];
      default:
        throw Error("Size not supported.");
    }
  };

  // ===========================================================================
  // Render
  // ===========================================================================

  if (!initials || noBadge) {
    return <Avatar icon={<UserOutlined />} onClick={onClick} size={size} style={{
      ...avatarStyle,
      ...style
    }} />;
  }
  return <Badge color={badgeColorOverride} dot status={badgeStatus} size={size === "small" ? "small" : "default"} offset={getBadgeOffset()} data-sentry-element="Badge" data-sentry-component="UserAvatar" data-sentry-source-file="user-avatar.tsx">
      <Avatar className="user-avatar" onClick={onClick} size={size} style={{
      ...avatarStyle,
      ...style
    }} data-sentry-element="Avatar" data-sentry-source-file="user-avatar.tsx">
        {initials}
      </Avatar>
    </Badge>;
}