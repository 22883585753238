import { DateUtility } from ".";

/**
 * Group the data by date.
 */
export type GroupedByDate<T> = {
  /**
   * The label of the group.
   */
  label: string;
  /**
   * The items in the group.
   */
  items: T[];
}[];

/**
 * Arguments for the `groupByDate` function.
 */
type GroupByDateArgs<T extends Record<string, any>, U = T> = {
  /**
   * The data to be grouped.
   */
  data: T[];
  /**
   * The `key` name of the date field in the data.
   */
  key: keyof T;
  /**
   * An optional mapper that maps each item to a new structure.
   * This will be the return type if provided.
   */
  mapper?: (item: T) => U;
};

/**
 * Groups an array of data by date.
 */
export function groupByDate<T extends Record<string, any>, U = T>({
  data,
  key,
  mapper,
}: GroupByDateArgs<T, U>): GroupedByDate<U> {
  const groupedData: GroupedByDate<U> = [];

  data.forEach((item) => {
    const dateValue = new Date(item[key]);
    let groupLabel: string;

    // Date is today.
    if (DateUtility.isToday({ date: dateValue })) {
      groupLabel = "Today";
    }
    // Date is yesterday.
    else if (DateUtility.isYesterday({ date: dateValue })) {
      groupLabel = "Yesterday";
    }
    // Date older than yesterday.
    else {
      groupLabel = DateUtility.getDate({ date: dateValue });
    }

    let group = groupedData.find((group) => group.label === groupLabel);

    if (!group) {
      group = { label: groupLabel, items: [] };
      groupedData.push(group);
    }

    group?.items.push(mapper ? mapper(item) : (item as unknown as U));
  });

  return groupedData;
}
