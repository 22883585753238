/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogInboundCallStatusMapper,
  CommunicationLogOutboundCallStatusMapper,
  CommunicationLogStatus,
} from "@/components/client/communication-log/enumerations";
import {
  CommunicationLog,
  CommunicationLogsGroupedByDate,
} from "@/components/client/communication-log/types";
import {
  Call,
  CallDirection,
  CallRecording,
  CallRouting,
  CallsConcludedQuery,
  CallsMissedQuery,
  CallsUserActiveQuery,
  Contact,
  UserProfile,
} from "@/components/client/graphql";
import { DateUtility } from "@/components/common/utilities";

/**
 * Internal call record.
 */
type CommunicationLogCall = Pick<
  Call,
  | "callSid"
  | "date"
  | "dateEnded"
  | "dateStarted"
  | "direction"
  | "duration"
  | "from"
  | "id"
  | "status"
  | "summary"
  | "to"
> & {
  /**
   * Call routings.
   */
  routings?: {
    /**
     * Unique identifier for the routing entry.
     */
    id: string;
    /**
     * The date and time the entry was routed to the agent.
     */
    date: string;
    /**
     * Agent remark about the call routing status.
     */
    remark?: string | null;
    /**
     * The status of the routing.
     */
    status: CallRouting["status"];
    /**
     * The user who handled the call.
     */
    user?: {
      /**
       * The user profile.
       */
      profile: {
        /**
         * The agent name who handled the call.
         */
        fullName: string;
      };
    };
  }[];
  /**
   * The user who handled the call.
   */
  user?: {
    /**
     * Unique identifier for the user.
     */
    id: string;
    /**
     * The user profile.
     */
    profile?: {
      /**
       * The agent name who handled the call.
       */
      fullName?: UserProfile["fullName"];
    };
  } | null;
  /**
   * The recording object of the call. Contains the `recordingUrl`.
   */
  recording?: Pick<
    CallRecording,
    "recordingUrl" | "synced" | "thirdPartyUrl" | "status"
  > | null;

  /** The contact object containing the details of the customer who was called. */
  toContact?: Omit<Contact, "callsMade" | "callsReceived"> | null;

  /** The contact object containing the details of the customer who made the call. */
  fromContact?: Omit<Contact, "callsMade" | "callsReceived"> | null;

  /**
   * Number of accumulated consecutive missed calls from a client. Calls are grouped by the phone number.
   */
  missedCount?: number;
};

/**
 * Arguments for the `fromCall` function.
 */
type FromCallArgs = {
  /**
   * Call record to map to a communication log.
   */
  call: CommunicationLogCall;
};

/**
 * Maps a "Call" record to a "CommunicationLog" record.
 */
export function fromCall({ call }: FromCallArgs): CommunicationLog {
  if (!call) {
    throw new Error("Call is required.");
  }

  /**
   * Direction of the call.
   */
  const direction =
    call.direction === CallDirection.Inbound
      ? CommunicationDirection.INBOUND
      : CommunicationDirection.OUTBOUND;

  /**
   * Phone number of the customer.
   */
  const clientPhoneNumber =
    call.direction === CallDirection.Inbound ? call.from : call.to;

  /**
   * Name of the customer.
   *
   * @returns The name of the customer or null if the name is not available.
   */
  const clientName =
    (call.direction === CallDirection.Inbound
      ? call.fromContact?.displayName
      : call.toContact?.displayName) || undefined;

  return {
    callSid: call.callSid!,
    call: call,
    clientName,
    clientPhoneNumber,
    date: new Date(call.date),
    dateEnded: call.dateEnded ? new Date(call.dateEnded) : undefined,
    dateStarted: call.dateStarted ? new Date(call.dateStarted) : undefined,
    direction,
    duration: call.duration ?? 0,
    from: call.from!,
    fromContact: call.fromContact || undefined,
    id: call.id!,
    routings:
      call.routings?.map((routing) => ({
        id: routing.id,
        agentName: routing.user?.profile.fullName!,
        date: new Date(routing.date),
        remark: routing.remark,
        status: routing.status,
      })) ?? [],
    user: call.user
      ? {
          name: call.user?.profile?.fullName ?? "",
          id: call.user?.id!,
        }
      : undefined,
    status: (call.direction === CallDirection.Inbound
      ? CommunicationLogInboundCallStatusMapper[
          call.status as keyof typeof CommunicationLogInboundCallStatusMapper
        ]
      : CommunicationLogOutboundCallStatusMapper[
          call.status as keyof typeof CommunicationLogOutboundCallStatusMapper
        ]) as CommunicationLogStatus,
    summary: call.summary ?? undefined,
    time: DateUtility.getTime({ date: new Date(call.date) }),
    to: call.to!,
    toContact: call.toContact || undefined,
    recording: call.recording ?? undefined,
    missedCount: call.missedCount,
  } satisfies CommunicationLog;
}

/**
 * Arguments for the `fromCallsGroupedByDate` function.
 */
type FromCallsGroupedByDateArgs = {
  /**
   * TODO: This must not be referring to the query directly. Create own type.
   *
   * Calls to map to communication logs.
   */
  calls:
    | CallsConcludedQuery["callsConcluded"]
    | CallsUserActiveQuery["callsUserActive"]
    | CallsMissedQuery["callsMissed"];
};

/**
 * Maps the calls to communication logs.
 */
export function fromCallsGroupedByDate({
  calls,
  ...rest
}: FromCallsGroupedByDateArgs) {
  const _communicationLog: CommunicationLogsGroupedByDate = [];

  /**
   * Adds the communication log to a communication log group.
   * If the group does not exist, it will be created.
   */
  function addCommunicationLogToGroup({
    call,
    groupLabel,
  }: {
    /**
     * The call to add to the group.
     */
    call:
      | CallsUserActiveQuery["callsUserActive"]["items"][0]
      | CallsConcludedQuery["callsConcluded"]["items"][0]
      | CallsMissedQuery["callsMissed"]["items"][0];
    /**
     * Label of the group to add the communication log to.
     * If this group does not exist, it will be created.
     */
    groupLabel: string;
  }) {
    /**
     * Index of the communication log group for matching the given group label.
     */
    let communicationLogGroupIndex = _communicationLog.findIndex(
      (log) => log.label === groupLabel,
    );

    // There is no existing communication log group for the given group label.
    if (communicationLogGroupIndex === -1) {
      // Create a new communication log group.
      _communicationLog.push({
        label: groupLabel,
        logs: [],
      });

      // Push the communication log to the newly created group.
      communicationLogGroupIndex = _communicationLog.findIndex(
        (log) => log.label === groupLabel,
      );
    }

    /**
     * Communication log created from the call record.
     */
    const communicationLog = fromCall({
      call,
      ...rest,
    });

    // Add the communication log to the group.
    if (communicationLog) {
      _communicationLog[communicationLogGroupIndex].logs.push(communicationLog);
    }
  }

  calls.items.forEach((call) => {
    /**
     * The date the call was initialized.
     */
    const callDate = new Date(call.date);

    // Get today's communication logs.
    if (DateUtility.isToday({ date: callDate })) {
      addCommunicationLogToGroup({ call, groupLabel: "Today" });
    }

    // Get yesterday's comminication logs.
    else if (DateUtility.isYesterday({ date: callDate })) {
      addCommunicationLogToGroup({ call, groupLabel: "Yesterday" });
    }

    // Get communication logs older than yesterday.
    else {
      addCommunicationLogToGroup({
        call,
        // Use the date as the group label.
        groupLabel: DateUtility.getDate({ date: callDate }),
      });
    }
  });

  return _communicationLog;
}

/**
 * Get the card color based on the communication log status.
 */
export function getCardColor(args: {
  /**
   * Indicates that the communication log is active.
   */
  active?: boolean;
  /**
   * Status of the communication log.
   */
  status: CommunicationLogStatus;
}) {
  switch (args.status) {
    case CommunicationLogStatus.PENDING:
    case CommunicationLogStatus.RINGING:
    case CommunicationLogStatus.WRAPPING_UP:
      return {
        background: args.active ? "bg-sky-100" : "bg-sky-100",
        border:
          args.status === CommunicationLogStatus.RINGING
            ? "border-l-4 border-solid border-tpl-blue"
            : undefined,
        icon: "!text-tpl-navy",
        iconHover: "",
        text: "",
        textHover: "",
      };
    case CommunicationLogStatus.ONGOING:
      return {
        background: "bg-semantic-green",
        border: undefined,
        icon: "",
        iconHover: "group-hover:text-tpl-navy",
        text: "text-white",
        textHover: "group-hover:text-tpl-navy",
      };
    case CommunicationLogStatus.COMPLETED:
      return {
        background: args.active ? "bg-sky-100" : "bg-white",
        icon: "!text-semantic-green",
        iconHover: "",
        text: "",
        textHover: "",
      };
    case CommunicationLogStatus.CANCELED:
    case CommunicationLogStatus.DECLINED:
    case CommunicationLogStatus.FAILED:
    case CommunicationLogStatus.MISSED:
    case CommunicationLogStatus.NO_RESPONSE:
      return {
        background: args.active ? "bg-sky-100" : "bg-white",
        border: undefined,
        icon: "!text-semantic-red",
        iconHover: "",
        text: "",
        textHover: "",
      };
    default:
      throw new Error(`Unknown status: ${args.status}`);
  }
}

/**
 * Get the icon based on the communication log status.
 */
export function getIcon(args: {
  /**
   * Status of the communication log.
   */
  status: CommunicationLogStatus;
}) {
  switch (args.status) {
    case CommunicationLogStatus.CANCELED:
    case CommunicationLogStatus.DECLINED:
    case CommunicationLogStatus.COMPLETED:
    case CommunicationLogStatus.WRAPPING_UP:
      return "phone-down";
    case CommunicationLogStatus.FAILED:
    case CommunicationLogStatus.MISSED:
    case CommunicationLogStatus.NO_RESPONSE:
    case CommunicationLogStatus.ONGOING:
    case CommunicationLogStatus.PENDING:
    case CommunicationLogStatus.RINGING:
      return "phone";
    default:
      throw new Error(`Unknown status: ${args.status}`);
  }
}
